<template>
  <div id="cultureMedium">
    <el-dialog
      :title="cultureMediumFormTitle"
      width="680px"
      :visible.sync="cultureMediumDialogVisible"
      :close-on-click-modal="false"
      @close="cultureMediumDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="cultureMediumFormRef"
        :model="cultureMediumForm"
        :rules="cultureMediumFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="培养基名称" prop="name">
              <el-input
                v-model="cultureMediumForm.name"
                placeholder="请输入培养基名称"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制日期" prop="preparationDate">
              <el-date-picker
                v-model="cultureMediumForm.preparationDate"
                placeholder="请选择配制日期"
                value-format="yyyy-MM-dd"
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="preparationBatch">
              <el-input
                v-model="cultureMediumForm.preparationBatch"
                placeholder="请输入批号"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制体积" prop="preparationVolume">
              <el-input
                v-model="cultureMediumForm.preparationVolume"
                placeholder="请输入配制体积"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="本品重量" prop="weight">
              <el-input
                v-model="cultureMediumForm.weight"
                placeholder="请输入本品重量"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加水量" prop="waterVolume">
              <el-input
                v-model="cultureMediumForm.waterVolume"
                placeholder="请输入加水量"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分装情况" prop="subpackage">
              <el-input
                v-model="cultureMediumForm.subpackage"
                placeholder="请输入分装情况"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="灭菌时间" prop="sterilizeTime">
              <el-input
                v-model="cultureMediumForm.sterilizeTime"
                placeholder="请输入灭菌时间"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度" prop="temperature">
              <el-input
                v-model="cultureMediumForm.temperature"
                placeholder="请输入温度"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="cultureMediumForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="cultureMediumFormTitle !== '新增培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '修改培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="cultureMediumForm.reviewResult"
                :disabled="cultureMediumFormTitle !== '复核培养基配制、灭菌记录'
                  && cultureMediumFormTitle !== '培养基配制、灭菌记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="cultureMediumDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cultureMediumFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="cultureMediumFormTitle === '培养基配制、灭菌记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="培养基名称">
        <el-input v-model="searchForm.name" placeholder="请输入培养基名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CULTURE_MEDIUM_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cultureMediumPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="name" label="培养基名称" />
      <el-table-column label="配制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.preparationDate">{{ scope.row.preparationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="preparationBatch" label="批号" />
      <el-table-column prop="preparationVolume" label="配制体积" />
      <el-table-column prop="weight" label="本品重量" />
      <el-table-column prop="waterVolume" label="加水量" />
      <el-table-column prop="subpackage" label="分装情况" />
      <el-table-column prop="sterilizeTime" label="灭菌时间" />
      <el-table-column prop="temperature" label="温度" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="preparer" label="配置人" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CULTURE_MEDIUM_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CULTURE_MEDIUM_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['CULTURE_MEDIUM_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cultureMediumPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addCultureMedium,
  deleteCultureMedium,
  updateCultureMedium,
  selectCultureMediumInfo,
  selectCultureMediumList,
  reviewCultureMedium
} from '@/api/quality/cultureMedium'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      cultureMediumDialogVisible: false,
      cultureMediumFormTitle: '',
      cultureMediumForm: {
        id: '',
        name: '',
        preparationDate: '',
        preparationBatch: '',
        preparationVolume: '',
        weight: '',
        waterVolume: '',
        subpackage: '',
        sterilizeTime: '',
        temperature: '',
        remarks: '',
        reviewResult: '',
        status: '',
        taskId: ''
      },
      cultureMediumFormRules: {
        name: [{ required: true, message: '培养基名称不能为空', trigger: ['blur', 'change']}]
      },
      cultureMediumPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectCultureMediumList(this.searchForm).then(res => {
      this.cultureMediumPage = res
    })
  },
  methods: {
    cultureMediumDialogClose () {
      this.$refs.cultureMediumFormRef.resetFields()
    },
    cultureMediumFormSubmit () {
      if (this.cultureMediumFormTitle === '培养基配制、灭菌记录') {
        this.cultureMediumDialogVisible = false
        return
      }
      this.$refs.cultureMediumFormRef.validate(async valid => {
        if (valid) {
          if (this.cultureMediumFormTitle === '新增培养基配制、灭菌记录') {
            this.cultureMediumForm.id = ''
            this.cultureMediumForm.status = 1
            await addCultureMedium(this.cultureMediumForm)
          } else if (this.cultureMediumFormTitle === '修改培养基配制、灭菌记录') {
            await updateCultureMedium(this.cultureMediumForm)
          } else if (this.cultureMediumFormTitle === '复核培养基配制、灭菌记录') {
            this.cultureMediumForm.status = 2
            await reviewCultureMedium(this.cultureMediumForm)
          }
          this.cultureMediumPage = await selectCultureMediumList(this.searchForm)
          this.cultureMediumDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cultureMediumFormTitle = '新增培养基配制、灭菌记录'
      this.cultureMediumDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCultureMedium(row.id)
        if (this.cultureMediumPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cultureMediumPage = await selectCultureMediumList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cultureMediumFormTitle = '修改培养基配制、灭菌记录'
      this.cultureMediumDialogVisible = true
      this.selectCultureMediumById(row.id)
    },
    handleReview (index, row) {
      this.cultureMediumFormTitle = '复核培养基配制、灭菌记录'
      this.cultureMediumDialogVisible = true
      this.selectCultureMediumById(row.id)
    },
    handleInfo (index, row) {
      this.cultureMediumFormTitle = '培养基配制、灭菌记录详情'
      this.cultureMediumDialogVisible = true
      this.selectCultureMediumById(row.id)
    },
    selectCultureMediumById (id) {
      selectCultureMediumInfo(id).then(res => {
        this.cultureMediumForm.id = res.id
        this.cultureMediumForm.name = res.name
        this.cultureMediumForm.preparationDate = res.preparationDate
        this.cultureMediumForm.preparationBatch = res.preparationBatch
        this.cultureMediumForm.preparationVolume = res.preparationVolume
        this.cultureMediumForm.weight = res.weight
        this.cultureMediumForm.waterVolume = res.waterVolume
        this.cultureMediumForm.subpackage = res.subpackage
        this.cultureMediumForm.sterilizeTime = res.sterilizeTime
        this.cultureMediumForm.temperature = res.temperature
        this.cultureMediumForm.remarks = res.remarks
        this.cultureMediumForm.reviewResult = res.reviewResult
        this.cultureMediumForm.status = res.status
        this.cultureMediumForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCultureMediumList(this.searchForm).then(res => {
        this.cultureMediumPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCultureMediumList(this.searchForm).then(res => {
        this.cultureMediumPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCultureMediumList(this.searchForm).then(res => {
        this.cultureMediumPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['CULTURE_MEDIUM_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `培养基配制、灭菌记录${this.cultureMediumForm.preparationDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
