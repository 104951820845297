import axios from '@/common/axios'
import qs from 'qs'

export function addCultureMedium (data) {
  return axios({
    method: 'post',
    url: '/quality/cultureMedium/add',
    data: qs.stringify(data)
  })
}

export function deleteCultureMedium (id) {
  return axios({
    method: 'delete',
    url: '/quality/cultureMedium/delete/' + id
  })
}

export function updateCultureMedium (data) {
  return axios({
    method: 'put',
    url: '/quality/cultureMedium/update',
    data: qs.stringify(data)
  })
}

export function selectCultureMediumInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/cultureMedium/info/' + id
  })
}

export function selectCultureMediumList (query) {
  return axios({
    method: 'get',
    url: '/quality/cultureMedium/list',
    params: query
  })
}

export function reviewCultureMedium (data) {
  return axios({
    method: 'put',
    url: '/quality/cultureMedium/updateReview',
    data: qs.stringify(data)
  })
}
